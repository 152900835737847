* {
    margin: 0;
    padding: 0;
}

.header {
    background-color: aliceblue;
    text-align: center;
    padding: 10px 15px;
}

.footer {
    background-color: aliceblue;
    text-align: center;
    padding: 10px 15px;
}

.centered-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: flex-start;
    min-height: calc(100vh - 133px);
    background-image: url(../public/img/gstform-bg.jpg);
    background-size: cover;
    background-position: center center;
    margin-top: -42px;
    padding-bottom: 40px;
    /* Set height to 100% of the viewport height */
}

.centered-container .next svg {
    width: 24px;
    color: #337ab7;
    cursor: pointer;
}

.centered-container form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.centeredscore-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    padding-top: 20px;
    min-height: calc(100vh - 133px);
    background: url("/public/img/gstScore-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    overflow-x: hidden;
    /* Set height to 100% of the viewport height */
}

.centeredscore-container svg.pie {
    width: 50%;
    margin: 0 auto;
    max-height: calc(100vh - 190px);
}

.centeredscore-container h2 {
    margin: 10px auto;
    font-size: 24px;
    font-weight: 400;
}

.gstform {
    width: 100%;
    margin: 0 auto;
    max-width: 400px;
    padding: 20px;
    background: #FFFFFF33;
    border: 1px solid #EFF0F6;
    padding-top: 100px;
}

.gstform label {
    display: block;
    /* Ensure labels are on their own line */
    margin-bottom: 5px;
    /* Add space below labels */
    font-weight: 400;
    font-size: 18px;
}

.gstform input {
    font-size: 18px;
    padding: 12px 15px;
}

input {
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    /* Ensure padding and border are included in element's total width and height */
}

.centered-container form button.btn:focus {
    outline: none;
}


/* Apply specific styles to input fields with type=text */

input[type="text"] {
    width: 100%;
    /* Set width to 100% of its container */
}


/* Apply specific styles to input fields when they are focused */

input:focus {
    outline: none;
    /* Remove default focus outline */
    border-color: #007bff;
    /* Change border color when focused */
}

.myLabel {
    color: blue;
    font-size: 16px;
}

.container.my {
    min-height: calc(100vh - 153px);
    margin: 15px auto;
    /* max-width: 200px; */
}

h2.score {
    text-align: center;
    line-height: 1.6;
    font-size: 20px !important;
}

.nextparent {
    margin-left: auto;
}

.centered-container form label {
    font-weight: 400;
}

.centered-container form input {
    margin-bottom: 15px;
}

.error-msg {
    color: #ff8888;
}

.step-indicator {
    display: flex;
    align-items: center;
}

.step {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 100px;
    height: 42px;
    width: 42px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    background: #EFF0F6;
}

.step.active {
    background-color: #0050d3;
    color: white;
}

.line {
    width: 50px;
    height: 6px;
    background-color: #eff0f6;
    margin: 0 5px;
    border-radius: 10px;
}

span.line.active>span:first-child {
    border-radius: 8px;
    width: 25px;
    height: 6px;
    position: absolute;
    background: #0050d3;
}

form.gstform .tba-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

form.gstform .tba-buttons .prev {
    margin-right: 15px;
    background: #eee;
    padding: 8px 15px;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
}

form.gstform .tba-buttons button {
    flex: 1 1;
    background: #b1bcea;
    border-color: #b1bcea;
    font-size: 18px;
    color: #000;
}

h2.title {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 75px;
}

.centered-containerstep>span.step:first-child:not(.active) {
    background: #0050d3;
    color: #fff;
}

.centered-containerstep span.line:not(.active) {
    background: #0050d3;
}

.continue-btn-h {
    background: #b1bcea;
    padding: 10px 15px;
    border-radius: 8px;
    color: #000;
    text-align: center;
    margin-top: 15px;
    cursor: pointer;
    font-size: 18px;
}

.centered-containerstep {
    display: flex;
    justify-content: center;
    position: relative;
    top: 165px;
}

.powered-by {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50px;
}

.powered-by .tba-img img {
    max-width: 100px;
    margin-top: 15px;
}

.success-h {
    min-height: calc(100vh - 133px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(../public/img/gstform-bg.jpg);
    background-size: cover;
    background-position: center center;
}

.success-h svg {
    height: 150px;
    width: 150px;
}

.success-h toast.success {
    font-size: 28px;
    line-height: 1.6;
    max-width: 450px;
    text-align: center;
}

@media (max-width: 575px) {
    .centered-container {
        padding: 15px;
    }
    .centered-containerstep {
        top: 135px;
    }
    h2.title {
        margin-top: 30px;
    }
    .success-h toast.success {
        font-size: 24px;
        padding: 15px;
    }
}

.legend {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 998;
    /* Ensure it's below the modal */
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    z-index: 999;
    /* Ensure it's above the overlay */
}

.modalx {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.modalx .modal-content {
    border: 0;
    padding: 30px;
    display: flex;
    flex-direction: column;
    border-radius: 0;
}

.modalx .modal-content button {
    border: 0;
    background: #b1bcea;
    margin: 8px 0;
    padding: 10px 15px;
    border-radius: 8px;
    color: #000;
    font-size: 18px;
}

.modalx .modal-content p {
    font-size: 20px;
}

.blur-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
}


/* .apexchart-container {
    margin-left: 26vw;
} */

.apexchart>div {
    display: flex;
    justify-content: center;
}

.gstscore-row {
    width: 100%;
}

.gstscore-row .tba-info-h .tbh-info {
    max-width: 300px;
    padding: 30px 15px;
}

.gstscore-row .tba-info-h {
    display: flex;
    justify-content: center;
}

.gstscore-row .tba-info-h .tbh-info>div {
    padding: 10px;
    margin-bottom: 15px;
    display: flex;
}

.gstscore-row .tba-info-h .tbh-info>div:nth-child(1) img,
.gstscore-row .tba-info-h .tbh-info>div:nth-child(2) img,
.gstscore-row .tba-info-h .tbh-info>div:nth-child(3) img {
    filter: invert(1);
}

.gstscore-row .tba-info-h .tbh-info>div:nth-child(1) {
    background: #133C55;
    color: #fff;
}

.gstscore-row .tba-info-h .tbh-info>div:nth-child(2) {
    background: #386FA4;
    color: #fff;
}

.gstscore-row .tba-info-h .tbh-info>div:nth-child(3) {
    background: #59A5D8;
    color: #fff;
}

.gstscore-row .tba-info-h .tbh-info>div:nth-child(4) {
    background: #84D2F6;
}

.gstscore-row .tba-info-h .tbh-info>div:nth-child(5) {
    background: #91E5F6;
}

.gstscore-row .tba-info-h .tbh-info>div span.highlight {
    font-weight: 500;
    flex: 1;
    text-align: right;
}

.gstscore-row .tba-info-h .tbh-info>div img {
    margin: 0 15px;
}

@media (max-width: 1365px) {
    .legend {
        bottom: inherit;
        top: 360px;
    }
}

@media (max-width: 991px) {
    .gstscore-row .tba-info-h .tbh-info {
        padding-top: 0;
    }
    .legend {
        top: 340px;
    }
    .gstscore-row .tba-info-h {
        margin-top: -65px;
    }
}

@media (max-width: 479px) {
    .legend {
        bottom: 50px;
        top: inherit;
    }
    .gstscore-row .tba-info-h {
        margin-top: -30px;
    }
}

.consult {
    font-size: 16px;
}


/* 
.col-md-2.report {
    margin-top: 50vh;
    background-color: #84D2F6;
    color: #fff;
    font-weight: bold;
    border-radius: 15px;
    padding: 12px 0 8px 20px;
    padding-left: 52px;
} */

button.reportbtn {
    background-color: #133c55;
    color: #fff;
    border-radius: 8px;
    padding: 10px 25px;
    font-weight: 500;   
    border: 0
}
.gstscore-info-row {
    padding-bottom: 30px;
}
.p-0 {
    padding: 0 !important;
}
.mt-30 {
    margin-top: 30px;
}
.d-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}
@media (max-width: 399px) {
    .centeredscore-container .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}